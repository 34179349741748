.App {
  text-align: center;
  padding: 20px;
}

nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

nav ul li {
  margin: 0 10px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

textarea {
  width: 45%;
  height: 200px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
}

input[type="file"] {
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.result {
  margin-top: 20px;
}

.result ul {
  list-style-type: none;
  padding: 0;
}

.result li {
  margin: 5px 0;
}

.result pre {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
  margin-bottom: 20px;
}